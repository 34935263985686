<template>
  <div class="certcode">
    <div class="top">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="certcode-cont">
      <div class="btn-top">
        <div>
        </div>
        <Input v-model="keyword"
               :placeholder="$t('common_search_keyword')"
               clearable
               search
               :enter-button="true"
               @on-search="changeKeyword"
               style="width: 200px;"
        />
      </div>
      <div class="table">
        <Table border :columns="columns" :data="dataList" :loading="loading"></Table>
      </div>
      <div class="page">
        <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
      </div>
    </div>


  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "list",
  data(){
    return {
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: '分销商名称',
          key: 'distributor'
        },
        {
          title: '分类',
          key: 'name',
        },
        {
          title: '条数',
          key: 'total',
        },
        {
          title: '分发时间',
          key: 'amount',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '确认时间',
          key: 'created_at',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.affirmtime * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '备注',
          key: 'remarks',
        },
        // {
        //   title: '操作',
        //   key: 'timelong',
        //   render: (h, params) => {
        //     return h('div', [
        //       h('Button', {
        //         props: {
        //           type: 'primary',
        //           size: 'small',
        //         },
        //         style: {
        //           marginRight: '5px'
        //         },
        //         on: {
        //           click: () => {
        //
        //           }
        //         }
        //       }, '复制地址'),
        //     ]);
        //   }
        // },
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      keyword:'',
      loading:false,
      categoryList:[],
    }
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
      };
      this.loading = true;
      this.api.certcode.datasetCertCodeDispenseList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.total = Number(res.count)
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.certcode{
  .top{
    margin: 20px 0 0 20px;
  }
  .certcode-cont{
    margin: 20px;
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    .btn-top{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
  }
  .mr10{
    margin-right: 10px;
  }
}
</style>
